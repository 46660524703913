<template>
  <div>
    <div>
      <v-row no-gutters>
        <template>
          <v-col>
            <div
              v-for="documento in documentos"
              :key="documento.id"
              class="mb-3"
            >
              <v-list-item
                :href="documento.local ? `${rota}/download/` + documento.id : documento.link"
                :target="self"
              >
                <v-list-item-icon>
                  <v-icon
                    v-text="'mdi-download'"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ documento.text_pt }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    documentos: {
      type: Array,
      default: () => [],
    },
    docsLength: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    halfLength() {
      return Math.ceil(this.docsLength / 2);
    },
    rota() {
      return process.env.VUE_APP_URL_SysAGA2;
    },
  },
};
</script>

<style>

</style>
