<template>
  <v-chip
    v-if="isNew"
    class="ma-2"
    text-color="white"
    color="red"
  >
    Novo
  </v-chip>
</template>

<script>
export default {
  name: 'ChipDocumentoNovo',
  props: {
    documento: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isNew() {
      return this.dataEntreTrintaDias(this.documento.created_at)
        || this.dataEntreTrintaDias(this.documento.updated_at);
    },
  },
  methods: {
    dataEntreTrintaDias(dateString) {
      const currentDate = new Date();
      const date = new Date(dateString);

      // Calculo da diferença entre dias
      const differenceInDays = (currentDate - date) / (1000 * 3600 * 24);
      return differenceInDays <= 30;
    },
  },
};
</script>
