<style>
  .list-documentos .v-list-item__title,
  .list-documentos .v-icon {
    white-space: normal;
    line-height: 20px;
    color: #089BCE;
  }
</style>
<template>
  <div class="container--fluid fill-height">
    <v-container>
      <TheLayoutPageTitle :title="$t('route.portal.documentos.title')" />
      <v-expansion-panels
        :elevation="6"
        rounded="xl"
        class="pb-10"
      >
        <v-expansion-panel class="list-documentos">
          <v-expansion-panel-header>
            <div>
              <h3 class="text-h5 font-weight-bold my-4">
                {{ $t(`documentos.opea.card.assunto`) }}
              </h3>
              <p>{{ $t(`documentos.opea.card.explicacao`) }}</p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="tipoOpea.length > 0">
            <v-list-item-group
              v-for="documento in tipoOpea"
              :key="documento.id"
            >
              <v-list-item
                :href="documento.link_portal"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon
                    v-text="'mdi-download'"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ documento.text_pt }} <ChipDocumentoNovo :documento="documento" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-else>
            <v-list-item-content>
              <v-list-item-title class="text-h5 text-center font-weight-bold">
                {{ 'Carregando arquivos do servidor!' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="list-documentos">
          <v-expansion-panel-header>
            <div>
              <h3 class="text-h5 font-weight-bold my-4">
                {{ $t(`documentos.aerodromo.card.assunto`) }}
              </h3>
              <p>{{ $t(`documentos.aerodromo.card.explicacao`) }}</p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="arquivosAerodromo.length > 0">
            <v-list-item-group
              v-for="documentoAero in arquivosAerodromo"
              :key="documentoAero.id"
            >
              <v-list-item
                :href="documentoAero.link_portal"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon
                    v-text="'mdi-download'"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ documentoAero.text_pt }} <ChipDocumentoNovo :documento="documentoAero" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-else>
            <v-list-item-content>
              <v-list-item-title class="text-h5 text-center font-weight-bold">
                {{ 'Carregando arquivos do servidor!' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="list-documentos">
          <v-expansion-panel-header>
            <div>
              <h3 class="text-h5 font-weight-bold my-4">
                {{ $t(`documentos.apoio.card.assunto`) }}
              </h3>
              <p>{{ $t(`documentos.apoio.card.explicacao`) }}</p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="arquivosApoio.length > 0">
            <v-list-item-group
              v-for="documentoApoio in arquivosApoio"
              :key="documentoApoio.id"
            >
              <v-list-item
                :href="documentoApoio.link_portal"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon
                    v-text="'mdi-download'"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ documentoApoio.text_pt }} <ChipDocumentoNovo :documento="documentoApoio" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-else>
            <v-list-item-content>
              <v-list-item-title class="text-h5 text-center font-weight-bold">
                {{ 'Carregando arquivos do servidor!' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import TheLayoutPageTitle from '../layout/TheLayoutPageTitle.vue';
import DocumentosList from './DocumentosList.vue';
import ChipDocumentoNovo from '../widget/ChipNovoDocumento.vue';

export default {
  name: 'Documentos',
  components: {
    TheLayoutPageTitle,
    // eslint-disable-next-line vue/no-unused-components
    DocumentosList,
    ChipDocumentoNovo,
  },
  data: () => ({
    itemOpea: '',
    tipoOpea: '',
    itemAerodromo: '',
    itemApoio: '',
    arquivosOpea: [],
    arquivosAerodromo: [],
    arquivosApoio: [],
  }),
  mounted() {
    this.buscandoDocumentos();
  },
  methods: {
    async buscandoDocumentos() {
      try {
        const [response1] = await Promise.all([
          this.$axios.post(`${process.env.VUE_APP_URL_API}/portal/downloads`),
        ]);
        this.arquivosOpea = response1.data.data;
        this.tipoOpea = this.arquivosOpea.filter((arquivos) => arquivos.tipo === 'DocumentoAuxiliarOPEA' && arquivos.status === 'Aprovado');
        this.arquivosAerodromo = this.arquivosOpea.filter((arquivos) => arquivos.tipo === 'DocumentoAuxiliarAerodromo' && arquivos.status === 'Aprovado');
        this.arquivosApoio = this.arquivosOpea.filter((arquivos) => arquivos.tipo === 'DocumentoAuxiliarApoio' && arquivos.status === 'Aprovado');
        console.log(this.tipoOpea);
        console.log(this.arquivosAerodromo);
        console.log(this.arquivosApoio);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
